import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"input":_vm.onCalendarDropdownStateChange},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-grow-1"},'div',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.openCalendar),callback:function ($$v) {_vm.openCalendar=$$v},expression:"openCalendar"}},[_c(VDatePicker,_vm._b({model:{value:(_vm.internalDates),callback:function ($$v) {_vm.internalDates=$$v},expression:"internalDates"}},'v-date-picker',_vm.$attrs,false),[_c('div',{staticClass:"d-flex justify-space-between flex-gap-16 flex-grow-1 mx-4 mb-4"},[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.onConfirm}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }