import type {
  PromotionSimpleCondition,
  PromotionSimpleNumericCondition,
  PromotionSimpleSelectCondition,
  PromotionSimpleSumCondition,
  PromotionSimpleTextCondition
} from '@futureecom/futureecom-js/dist/services/promotion-service'

const simpleTextCondition: PromotionSimpleTextCondition = {
  rule: '',
  type: 'text',
  selectedOperator: 'begins with',
  value: ''
}

const simpleNumericCondition: PromotionSimpleNumericCondition = {
  rule: '',
  type: 'numeric',
  selectedOperator: '=',
  value: null
}

const simpleSumCondition: PromotionSimpleSumCondition = {
  rule: '',
  type: 'sum',
  selectedOperator: '=',
  value: null
}

const simpleSelectCondition: PromotionSimpleSelectCondition = {
  rule: '',
  type: 'select',
  selectedOperator: '=',
  value: null
}

export const conditionOptions: (PromotionSimpleCondition & { name: string })[] = [
  { name: 'Item SKU', ...simpleTextCondition, rule: 'items.*.sku' },
  { name: 'Item name', ...simpleTextCondition, rule: 'items.*.name' },
  { name: 'Item brand', ...simpleTextCondition, rule: 'items.*.brand' },
  { name: "Item's badge code", ...simpleTextCondition, rule: 'items.*.badges.*.code' },
  { name: "Item's tag name", ...simpleTextCondition, rule: 'items.*.tags.*.name' },
  { name: 'Shipping region', ...simpleTextCondition, rule: 'shipping_address.administrative_area' },
  { name: 'Total items amount', ...simpleNumericCondition, rule: 'summary.total_items_amount.amount' },
  { name: 'Total items count', ...simpleNumericCondition, rule: 'summary.total_items_count' },
  { name: 'Items quantity', ...simpleSumCondition, rule: 'items.*.quantity' },
  { name: 'Items type', ...simpleSelectCondition, rule: 'items.*.type' }
]

export const getConditionName = (rule: string): string => {
  return conditionOptions.find((item) => item.rule === rule)?.name || ''
}
