import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.availableTargetConditions.length)?_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","text":"","depressed":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.label)+" ")])]}}],null,false,705508496)},[_c(VList,[_c(VListItemGroup,_vm._l((_vm.availableTargetConditions),function(item){return _c(VListItem,{key:item.rule,on:{"click":function($event){return _vm.select(item.rule)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.name)))])],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }